/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  SafeModuleSetup,
  SafeModuleSetupInterface,
} from "../../../../../../../@safe-global/safe-modules-deployments/dist/assets/safe-4337-module/v0.3.0/SafeModuleSetup";

const _abi = [
  {
    inputs: [
      {
        internalType: "address[]",
        name: "modules",
        type: "address[]",
      },
    ],
    name: "enableModules",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class SafeModuleSetup__factory {
  static readonly abi = _abi;
  static createInterface(): SafeModuleSetupInterface {
    return new Interface(_abi) as SafeModuleSetupInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): SafeModuleSetup {
    return new Contract(address, _abi, runner) as unknown as SafeModuleSetup;
  }
}
