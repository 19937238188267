/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  SafeWebauthnSignerFactory,
  SafeWebauthnSignerFactoryInterface,
} from "../../../../../../../@safe-global/safe-modules-deployments/dist/assets/safe-passkey-module/v0.2.1/SafeWebauthnSignerFactory";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "signer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "x",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "y",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "P256.Verifiers",
        name: "verifiers",
        type: "uint176",
      },
    ],
    name: "Created",
    type: "event",
  },
  {
    inputs: [],
    name: "SINGLETON",
    outputs: [
      {
        internalType: "contract SafeWebAuthnSignerSingleton",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "x",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "y",
        type: "uint256",
      },
      {
        internalType: "P256.Verifiers",
        name: "verifiers",
        type: "uint176",
      },
    ],
    name: "createSigner",
    outputs: [
      {
        internalType: "address",
        name: "signer",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "x",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "y",
        type: "uint256",
      },
      {
        internalType: "P256.Verifiers",
        name: "verifiers",
        type: "uint176",
      },
    ],
    name: "getSigner",
    outputs: [
      {
        internalType: "address",
        name: "signer",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "message",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
      {
        internalType: "uint256",
        name: "x",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "y",
        type: "uint256",
      },
      {
        internalType: "P256.Verifiers",
        name: "verifiers",
        type: "uint176",
      },
    ],
    name: "isValidSignatureForSigner",
    outputs: [
      {
        internalType: "bytes4",
        name: "magicValue",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class SafeWebauthnSignerFactory__factory {
  static readonly abi = _abi;
  static createInterface(): SafeWebauthnSignerFactoryInterface {
    return new Interface(_abi) as SafeWebauthnSignerFactoryInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): SafeWebauthnSignerFactory {
    return new Contract(
      address,
      _abi,
      runner
    ) as unknown as SafeWebauthnSignerFactory;
  }
}
