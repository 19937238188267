/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  AddModulesLib,
  AddModulesLibInterface,
} from "../../../../../../../@safe-global/safe-modules-deployments/dist/assets/safe-4337-module/v0.2.0/AddModulesLib";

const _abi = [
  {
    inputs: [
      {
        internalType: "address[]",
        name: "modules",
        type: "address[]",
      },
    ],
    name: "enableModules",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class AddModulesLib__factory {
  static readonly abi = _abi;
  static createInterface(): AddModulesLibInterface {
    return new Interface(_abi) as AddModulesLibInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): AddModulesLib {
    return new Contract(address, _abi, runner) as unknown as AddModulesLib;
  }
}
