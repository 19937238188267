/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  DaimoP256Verifier,
  DaimoP256VerifierInterface,
} from "../../../../../../../@safe-global/safe-modules-deployments/dist/assets/safe-passkey-module/v0.2.1/DaimoP256Verifier";

const _abi = [
  {
    stateMutability: "nonpayable",
    type: "fallback",
  },
] as const;

export class DaimoP256Verifier__factory {
  static readonly abi = _abi;
  static createInterface(): DaimoP256VerifierInterface {
    return new Interface(_abi) as DaimoP256VerifierInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): DaimoP256Verifier {
    return new Contract(address, _abi, runner) as unknown as DaimoP256Verifier;
  }
}
