/**
 * The word lists are from https://github.com/mmkal/ts/tree/main/packages/memorable-moniker/src/dict
 */
export const animalsDict = `
aardvark
albatross
alligator
alpaca
ant
anteater
antelope
ape
armadillo
baboon
badger
barracuda
bat
bear
beaver
bee
binturong
bird
bison
bluebird
boar
bobcat
buffalo
butterfly
camel
capybara
caracal
caribou
cassowary
cat
caterpillar
cattle
chameleon
chamois
cheetah
chicken
chimpanzee
chinchilla
chough
coati
cobra
cockroach
cod
cormorant
cougar
coyote
crab
crane
cricket
crocodile
crow
cuckoo
curlew
deer
degu
dhole
dingo
dinosaur
dog
dogfish
dolphin
donkey
dotterel
dove
dragonfly
duck
dugong
dunlin
eagle
echidna
eel
eland
elephant
elk
emu
falcon
ferret
finch
fish
flamingo
fly
fox
frog
gaur
gazelle
gecko
gerbil
giraffe
gnat
gnu
goat
goldfinch
goosander
goose
gorilla
goshawk
grasshopper
grouse
guanaco
gull
hamster
hare
hawk
hedgehog
heron
herring
hippopotamus
hoatzin
hoopoe
hornet
horse
human
hummingbird
hyena
ibex
ibis
iguana
impala
jackal
jaguar
jay
jellyfish
jerboa
kangaroo
kingfisher
kinkajou
koala
kookaburra
kouprey
kudu
lapwing
lark
lemur
leopard
lion
lizard
llama
lobster
locust
loris
louse
lynx
lyrebird
macaque
macaw
magpie
mallard
mammoth
manatee
mandrill
marmoset
marmot
meerkat
mink
mole
mongoose
monkey
moose
mosquito
mouse
myna
narwhal
newt
nightingale
octopus
okapi
opossum
orangutan
oryx
ostrich
otter
owl
oyster
panther
parrot
panda
partridge
peafowl
pelican
penguin
pheasant
pig
pigeon
pika
pony
porcupine
porpoise
pug
quail
quelea
quetzal
rabbit
raccoon
ram
rat
raven
reindeer
rhea
rhinoceros
rook
salamander
salmon
sand
sandpiper
sardine
seahorse
seal
shark
sheep
shrew
siamang
skunk
sloth
snail
snake
spider
squid
squirrel
starling
stegosaurus
swan
tamarin
tapir
tarsier
termite
tiger
toad
toucan
turaco
turkey
turtle
umbrellabird
vinegaroon
viper
vulture
wallaby
walrus
wasp
waxwing
weasel
whale
wobbegong
wolf
wolverine
wombat
woodpecker
worm
wren
yak
zebra
`

export const adjectivesDict = `
admirable
energetic
lucky
affable
enjoyable
magnificent
affectionate
enthusiastic
marvelous
agreeable
euphoric
meritorious
amazing
excellent
merry
amiable
exceptional
amused
excited
nice
amusing
extraordinary
noble
animated
exultant
outstanding
appreciative
fabulous
overjoyed
astonishing
faithful
passionate
authentic
fantastic
peaceful
believable
fervent
placid
benevolent
fortunate
pleasant
blissful
friendly
pleasing
bouncy
fun
pleasurable
brilliant
genuine
positive
bubbly
glad
praiseworthy
buoyant
glorious
prominent
calm
good
proud
charming
relaxed
cheerful
reliable
cheery
gracious
respectable
clever
grateful
sharp
comfortable
great
sincere
comical
happy
spirited
commendable
heartfelt
splendid
confident
honest
superb
congenial
honorable
superior
content
hopeful
terrific
cordial
humorous
thankful
courteous
incredible
tremendous
dedicated
inspirational
triumphant
delighted
jolly
trustworthy
delightful
jovial
trusty
dependable
joyful
truthful
devoted
joyous
uplifting
docile
jubilant
victorious
dynamic
keen
vigorous
eager
kind
virtuous
earnest
laudable
vivacious
easygoing
laughing
whimsical
ebullient
likable
witty
ecstatic
lively
wonderful
elated
lovely
worthy
emphatic
loving
zealous
enchanting
loyal
zestful
`
